import React, { useState } from "react";
import Linkify from "react-linkify";

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const questions = [
    {
      question: "What is this?",
      answer:
        "The OG commemorative memento: a digital keepsake for eternity, crafted unofficially by alumni for a bit of fun. Plus, this weekend, it's your golden ticket to enjoy some 🇺🇦 beers when you claim it. When this site gets shut down the memento will be in your wallet as a memory.",
    },
    {
      question: "What is this smcm.me?",
      answer:
        "It's your roadmap to snagging that digital token via the MetaMask wallet. The site is temporary. But the timeless keepsake from SMCM Alumni Weekend 2024 is forever.",
    },
    {
      question: "Who's in charge?",
      answer:
        "Constantin K. '01. Former Alumni Council member. Ask Sushinsky. The memento is real. The experience is OG. The project is unofficial. ",
    },
    {
      question: "What's a MetaMask?",
      answer:
        "Meet the top dog of digital wallets for web3: MetaMask. It's where you stash tokens, including this memento, and so much more. In essence it's an app that generates for your eyes only pass phrase. This pass phrase will work on ANY web3 digital wallet. Thus your memento won't be tied to an app or a vendor. It will live on a blockchain accessible using MetaMask or any other web3 wallet. You can skip writing down the pass phrase while at the Alumni Weekend, but do it later. Sounds futuristic? Just Google MetaMask and see for yourself.",
    },

    {
      question: "How is this free?",
      answer:
        "Constantin's got you covered—he's already picked up the tab for both the mementos and the beers. Consider it a gift from alumn to alumn!",
    },
    {
      question: "Why is this safe?",
      answer:
        "Claiming is a stealth mode affair—no personal info required, no payment needed. And with MetaMask? You're in the safest of hands. ",
    },
    {
      question: "Need support? Message me.",
      answer:
        "Join this Signal group or find me on campus. Look for 🇺🇦 colors.",
    },
    {
      question: "How many beers?",
      answer:
        "Grab them while you can—quantities are limited, and some pack a serious punch. Sip with care!",
    },
    {
      question: "Where do I get the beer?",
      answer: "See step 3 above taking you to TokenGatedBeer.com",
    },
    {
      question: "When does the claim ends?",
      answer:
        "11 PM ET on Sunday, June 9th, 2024. But don't wait until the last minute. There are only 370 Proof of Attendance mementos available. ",
    },
    {
      question: "But Why?",
      answer:
        "This token lives on the blockchain. It's a forever keepsake. You own this token. It's yours. It's a piece of history. It's a digital proof you we were here. It's a piece of the future. It's a piece of the present. It's a piece of you. It's a piece of us. It's a community building primitive. Oh, and its programmable. I created a token gated site to give out beer. Perhaps next year you can create a benefit for others. But why wait until next year?",
    },
    {
      question: "I can't see my token in my wallet.",
      answer: (
        <Linkify>
          {
            "It's yours if you've claimed it. It doesn't show under the NFTs tab (it's to prevent spam). To peek at it in MetaMask hit the Portfolio button in MetaMask. But hey, you'll need to add the Linea blockchain on your network list first. A tad tricky, but hang tight; it's getting simpler soon. In the meantime, dive into my tutorial video for a smooth setup and a cheeky escape from the social whirlwind. https://youtu.be/0Xd9ow07tM4?si=CD_TCZRkUUnLEB5v. You can also see the token on the claim site above."
          }
        </Linkify>
      ),
    },
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <h4>Frequently Asked Questions</h4>
      <div className="accordion">
        {questions.map((item, index) => (
          <div className="accordion-item" key={index}>
            <div
              className="accordion-header"
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
            </div>
            <div
              className={`accordion-body ${openIndex === index ? "show" : ""}`}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
