import React from "react";
import obolon from "../obolonsmall.jpeg";
import claimsite from "../claimsite.jpeg";

function Steps() {
  return (
    <>
      <div>
        <h3>3 Steps to Follow</h3>
        <div className="step">
          <a
            href="https://metamask.io/download/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../MetaMask_Fox.svg").default}
              alt="Download MetaMask app."
            />
          </a>
          <p>
            1:{" "}
            <a
              href="https://metamask.io/download/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>{" "}
            the MetaMask app. Most trusted web3 wallet. See FAQ.{" "}
          </p>
        </div>
        <div className="step">
          <a
            href="https://app.phosphor.xyz/constantin/drops/e18f3192-d8cb-47d1-91ea-95f07eef0187/0f1b28f5-e176-45d8-8d99-1fc6783e25b4/c51723d5-2601-4143-8b6b-87ae393f42cf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={claimsite} alt="Claim" />
          </a>

          <p>
            2:{" "}
            <a
              href="https://app.phosphor.xyz/constantin/drops/e18f3192-d8cb-47d1-91ea-95f07eef0187/0f1b28f5-e176-45d8-8d99-1fc6783e25b4/c51723d5-2601-4143-8b6b-87ae393f42cf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Claim
            </a>{" "}
            the free token with MetaMask. YouTube{" "}
            <a
              href="https://www.youtube.com/watch?v=Dw_TDOE6JT0"
              target="_blank"
              rel="noopener noreferrer"
            >
              preview
            </a>
            .
          </p>
        </div>
        <div className="step">
          <img src={obolon} alt="Obolon beers" />

          <p>
            3: Token gets you free{" "}
            <a
              href="https://TokenGatedBeer.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ukrainian
            </a>{" "}
            beer. While supplies last.
          </p>
        </div>
      </div>
    </>
  );
}

export default Steps;
