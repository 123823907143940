import React from "react";
import TitleAndLogo from "./components/TitleAndLogo";
import Description from "./components/Description";
import Steps from "./components/Steps";
import FAQ from "./components/FAQ";

function App() {
  return (
    <div className="container">
      <div className="section">
        <TitleAndLogo />
      </div>
      <div className="menu">
        <Description />
      </div>
      <div className="menu">
        <Steps />
      </div>
      <div className="menu">
        <FAQ />
      </div>
      <div className="footer">
        <p>
          <a
            href="https://signal.group/#CjQKIGWphIXyyhD7pOsOxymGYpI1-Y4RUwPAgav8DRImFG_WEhB23NNkTmXYwfGIyVyNitXz>"
            target="_blank"
            rel="noopener noreferrer"
          >
            Questions? Hop on Signal
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
