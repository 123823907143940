import React from "react";

function Description() {
  return (
    <div>
      <h2>Commemorative token unlocks access to 🇺🇦 beers</h2>
    </div>
  );
}

export default Description;
