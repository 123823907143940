import React from "react";
import badgesmall from "../badgesmall.png";

function TitleAndLogo() {
  return (
    <div>
      <h1>CLAIM OG MEMENTO</h1>

      <h1>
        <img src={badgesmall} alt="Logo" />
      </h1>
    </div>
  );
}

export default TitleAndLogo;
